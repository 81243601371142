import { supabaseClient, User } from '@supabase/supabase-auth-helpers/nextjs';
import { ProductWithPrice, UserDetails, Message, Reply, Featured } from 'types';

export const supabase = supabaseClient;

export const getActiveProductsWithPrices = async (): Promise<ProductWithPrice[]> => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.type', 'recurring')
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });
  if (error) {
    // console.log(error.message);
    throw error;
  }
  return data || [];
};

export const MyUserDetails = async (myID: any) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .select('id, first_name')
    .eq('id', myID)
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const updateUserName = async (user: User, full_name: string) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .update({
      full_name: full_name,
      updated_at: new Date()
    })
    .eq('id', user.id);

    if (error) {
      // console.log(error);
      throw error;
    }
    return data || [];
};

export const updatePersonalDetails = async (user: User, userDB: UserDetails) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .update({
      full_name: userDB?.full_name,
      first_name: userDB?.first_name,
      last_name: userDB?.last_name,
      billing_address: userDB?.billing_address,
      updated_at: new Date()
    })
    .eq('id', user.id);

    if (error) {
      // console.log(error.message);
      throw error;
    }
    return data || [];
};

export const updateUserRole = async (userDB: UserDetails) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .update({
      role: userDB?.role,
      updated_at: new Date()
    })
    .eq('id', userDB.id);

    if (error) {
      // console.log(error.message);
      throw error;
    }
    return data || [];
};

export const myCourses = async (myID: any) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .select('my_courses')
    .eq('id', myID);
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const myCompletedCourses = async (myID: any) => {
  const { data, error } = await supabase
    .from('completed_courses')
    .select('*')
    .eq('user_id', myID);
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const updateUserMyCourses = async (myID: any, myCourses: any) => {
  console.log({myID, myCourses})
  const { data, error } = await supabase
    .from('users')
    .update({
      my_courses: myCourses,
      updated_at: new Date()
    })
    .eq('id', myID);

    if (error) {
      // console.log(error.message);
      throw error;
    }
    return data || [];
};

export const createMessage = async (myID: any, myMessage: Message) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .insert({
      user_id: myID,
      title: myMessage.title,
      content: myMessage.content,
      type: myMessage.type,
      publish: myMessage.publish || true,
      publish_at: myMessage.publish_at || new Date(),
      created_at: new Date(),
      updated_at: new Date()
    })
    if (error) {
      // console.log(error.message);
      throw error;
    }
    return data || [];
};

export const updateMessage = async (id: any, updatedMessage: Message) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .update({
      title: updatedMessage.title,
      content: updatedMessage.content,
      type: updatedMessage.type,
      publish: updatedMessage.publish || true,
      publish_at: updatedMessage.publish_at || new Date(),
      updated_at: new Date()
    })
    .eq('id', id);
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const DeleteMessage = async (id: any) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .delete()
    .match({ id })
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const GetMessage = async (id: any) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .select('*')
    .eq('id', id)
    .limit(1)
    .single()
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const MyMessages = async (myID: any, type: string) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .select('*, author: users(id, full_name, first_name, last_name, avatar_url, email, role)')
    .eq('user_id', myID)
    .eq('type', type)
    .order('publish_at', { ascending: false });
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const AllMessages = async (type: string) => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .select('*, author: users(id, full_name, first_name, last_name, avatar_url, email, role)')
    .eq('type', type)
    .order('publish_at', { ascending: false });
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const createReply = async (myID: any, myReply: Reply) => {
  const { data, error } = await supabase
    .from<Reply>('replies')
    .insert({
      user_id: myID,
      message_id: myReply.message_id,
      content: myReply.content,
      publish: myReply.publish || true,
      publish_at: myReply.publish_at || new Date(),
      created_at: new Date(),
      updated_at: new Date()
    })
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const GetReplies = async (message_id: any) => {
  const { data, error } = await supabase
    .from<Reply>('replies')
    .select('*, author: users(id, full_name, first_name, last_name, avatar_url, email, role)')
    .eq('message_id', message_id)
    .order('publish_at', { ascending: false });
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const DeleteRepliesByMessage = async (id: any) => {
  const { data, error } = await supabase
    .from<Reply>('replies')
    .delete()
    .eq('message_id', id)
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const DeleteReply = async (id: any) => {
  const { data, error } = await supabase
    .from<Reply>('replies')
    .delete()
    .match({ id })
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const GetReplyAuthor = async (user_id: any) => {
  const { data, error } = await supabase
    .from<UserDetails>('users')
    .select('id, full_name, first_name, last_name, avatar_url, email, role')
    .eq('id', user_id)
    .limit(1)
    .single()
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};


export const RWRDashboardNews = async () => {
  const { data, error } = await supabase
    .from<Message>('messages')
    .select('*, author: users(id, full_name, first_name, last_name, avatar_url, email, role)')
    .limit(5)
    .eq('type', 'news')
    .order('updated_at', { ascending: false });
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const RWRFeatured = async (id: any) => {
  const { data, error } = await supabase
    .from<Featured>('featured')
    .select('*')
    .eq('id', id)
    .limit(1)
    .single();
    if (error) {
      console.log(error.message);
      throw error;
    }
    return data || [];
};

export const updateRWRFeatured = async (id: string, text: string, featured_content: string) => {
  const { data, error } = await supabase
    .from('featured')
    .update({
      text: text,
      featured_content: featured_content,
      updated_at: new Date()
    })
    .eq('id', id);

    if (error) {
      // console.log(error.message);
      throw error;
    }
    return data || [];
};
